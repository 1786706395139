@tailwind base;
@tailwind components;
@tailwind utilities;

section {
    scroll-margin-top: 80px;
}

.amplify-button{
    @apply bg-blue-500 text-white font-bold py-2 px-4 rounded;
}